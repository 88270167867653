/* tabsStyles.css */

/* .tab-list {
  display: flex;
  border: 1px solid
    light-dark(var(#114684), var((#114684));
  background-color: light-dark(
    var((#114684)),
    var((#114684))
  );
  border-radius: 4px;
  overflow: hidden;
} */

/* .tab {
  flex: 1;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  background-color: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-dark-6)
  );
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
} */

/* .tab:hover {
  background-color: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-5)
  );
} */

/* .tab[data-active] {
  z-index: 1;
  background-color: var(--mantine-color-blue-filled);
  color: var(--mantine-color-white);
} */

.tab-list {
  border-radius: 0.75rem;
  border: none;
}

.tab {
  position: relative;
  border: 1px solid light-dark(#e9ecef, #424242);
  background-color: light-dark(hsl(0, 0%, 100%), #2e2e2e);
  font-weight: 600;
  min-width: 175px;

  &:first-of-type {
    border-radius: 0.75rem 0 0 0.75rem;
  }

  &:last-of-type {
    border-radius: 0 0.75rem 0.75rem 0;
  }

  & + & {
    border-left-width: 0;

    /* @mixin rtl {
      border-right-width: 0;
      border-left-width: 1px;
    } */
  }

  &:hover {
    background-color: light-dark(#f8f9fa, #3b3b3b);
    border-color: var(--yellow-primary) !important;
    outline: none;
  }

  &[data-active='true'] {
    z-index: 1;
    background-color: var(--yellow-primary);
    border-color: var(--yellow-primary);
    color: var(--blue-primary);
  }
}
