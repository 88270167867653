* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 14px;
}

table,
.divTable {
  border: 1px solid lightgray;
  width: fit-content;
}

thead th,
.primary-btn,
.mantine-TableHeadCell-Content .mantine-ActionIcon-root,
.mantine-TableHeadCell-Content .mantine-UnstyledButton-root {
  color: #ffffff !important;
  background-color: #114684 !important;
  opacity: 1;
}

.primary-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: auto !important;
}

tbody td {
  white-space: normal !important;
}

.tr {
  display: flex;
}

tr,
.tr {
  width: fit-content;
  height: 30px;
}

th,
.th,
td,
.td {
  box-shadow: inset 0 0 0 1px lightgray;
  padding: 0.25rem;
}

th,
.th {
  padding: 2px 4px;
  position: relative;
  font-weight: bold;
  text-align: center;
  height: 30px;
}

td,
.td {
  height: 30px;
}

.mantine-TableHeadCell-Content-Wrapper {
  white-space: normal !important;
}

.progress-report-edit-modal {
  .mantine-Paper-root.mantine-Modal-content {
    max-width: 650px;
    flex: auto;
  }
}

tbody tr > td {
  overflow: visible !important;
  z-index: 10 !important;
}

tbody tr td:nth-child(2),
tbody tr td:nth-child(1) {
  z-index: 1 !important;
}

.pagination-wrapper > div {
  margin-top: 0;
}

/* .capital-assets-table {
  table>thead>tr>th.custom-column {
    width: 35px !important;
  }
} */

.contractor-table thead > tr > th {
  padding: 0 1rem;
}

.contractor-table tbody > tr > td {
  padding: 0 1rem;
}

.contractor-table thead th {
  /* color: #114684 !important; */
  /* color: #0b2e56 !important; */
  color: #0c315d !important;
  background-color: white !important;
  opacity: 1;
  font-weight: 600 !important;
  font-size: 13px !important;
}

.cms-contractor-table {
  color: #0c315d !important;
  background-color: white !important;
  opacity: 1;
  font-weight: 600 !important;
  font-size: 13px !important;
  box-shadow: none;
  border: none;
  border: 1px solid gray;
  border-radius: 5px;
  outline: none;

  thead > tr > th {
    padding: 0 1rem;
    border: none;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    box-shadow: none;
  }

  tbody > tr > td {
    padding: 0 1rem;
    border: none;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    box-shadow: none;
    /* border: 1px solid gray; */
  }
}

/* .mantine-FileInput-placeholder {
  color: black !important;
} */

.legal-form-table {
  tbody > tr > td {
    padding: 0 1rem;
  }
}

.legalform_pdfPreference .mantine-MultiSelect-values {
  height: 100%;
  max-height: 75px !important;
  overflow: auto !important;
}

.profit_loss_report {
  background: #11468433 !important;
}

.profit_loss_report:hover {
  background: #11468433 !important;
}

/*ocr report css*/

/* .ocr_report_table table tbody tr td:first-child {
  white-space: nowrap !important;
} */
