:root {
  --swiper-image-ratio: 33.3%; /* ratio 16:9 = 56.25% */

  --swiper-width: 95%;
  --swiper-inactive-scale: 0.85; /* makes the other slide smaller */

  /* responsive settings */
  --swiper-mobile-width: 90%;
  --swiper-mobile-inactive-scale: 0.95;
}

.swiper-slide:not(.swiper-slide-prev):not(.swiper-slide-next):not(
    .swiper-slide-active
  ) {
  scale: 0.8 !important;
  transform-origin: right;
  height: 100% !important;
}
.swiper-slide-active
  + *
  + .swiper-slide:not(.swiper-slide-prev):not(.swiper-slide-next):not(
    .swiper-slide-active
  ) {
  scale: 0.8 !important;
  transform-origin: left;
  height: 100% !important;
}

.swiper-slide {
  animation: normal 250ms linear;
}

.container {
  position: relative;
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  max-width: 120rem;
}

.swiper {
  position: relative;
  width: 100%;
  /* height: calc(var(--swiper-width) * var(--swiper-image-ratio) / 100%); */
  overflow: hidden;
}

.swiper-wrapper {
  align-items: center !important;
}

.swiper-slide {
  position: relative;
  width: var(--swiper-width);
  opacity: 0.5;
  transform: scale(0.84);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  border-radius: 0.4285rem;
  height: 100% !important;
}

.swiper-backface-hidden .swiper-slide {
  transform: scale(0.84) translateZ(0);
}

.swiper-slide.swiper-slide-active {
  transform: scale(1) !important;
  opacity: 1 !important;
}

.swiper-backface-hidden .swiper-slide.swiper-slide-active {
  transform: scale(1) translateZ(0) !important;
}

.swiper-image {
  position: relative;
  width: 100%;
  padding-top: var(--swiper-image-ratio);
}

.swiper-image .image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #929ec9;
}

.swiper-button-next,
.swiper-button-prev {
  padding: 8px;
  width: 12px;
  height: 12px;
  margin-top: 10px;
  /* background-color: rgba(85, 84, 84, 0.4); */
  border-radius: 50%;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: #358ce3;
  font-size: 50px;
}

.swiper-button-next {
  transform: translate(50%, -50%);
  right: calc((100% - var(--swiper-width)) / 2);
}

.swiper-button-prev {
  transform: translate(-50%, -50%);
  left: calc((100% - var(--swiper-width)) / 2);
}

.swiper.swiper-initialized {
  padding: 20px 0;
}

.swiper-pagination {
  bottom: auto;
}
@media only screen and (max-width: 768px) {
  .swiper-slide {
    width: var(--swiper-mobile-width);
    transform: scale(var(--swiper-mobile-inactive-scale));
  }

  .swiper-backface-hidden .swiper-slide.swiper-slide {
    transform: scale(var(--swiper-mobile-inactive-scale)) translateZ(0);
  }

  .swiper-button-next {
    right: calc((100% - var(--swiper-mobile-width)) / 2);
  }

  .swiper-button-prev {
    left: calc((100% - var(--swiper-mobile-width)) / 2);
  }
}
